import { useEffect, useState } from "react";
import { toaster } from "evergreen-ui";
import QrScanner from "qr-scanner";

import isPipeToken from "../../../helpers/isPipeToken.helper";
import useAuthContext from "../../AuthProvider/AuthContext.hook";
import { IEventType } from "../../../../api/Types";

export interface IAvailableEvents {
    decoded: false | {
        id: number;
    },
    result: QrScanner.ScanResult['data'] | null,
}

const useAvailableEvents = ({ decoded, result }: IAvailableEvents) => {
    const { api } = useAuthContext();

    const [availableEvents, setAvailableEvents] = useState<IEventType[]>([]);

    const checkAvailableEvents = async () => {
        try {
            if (isPipeToken(result) && decoded && 'id' in decoded) {
                const response = await api.getPipeAvailableEvents(decoded.id, result);            
                if (response.data) {
                    setAvailableEvents(response.data.results);
                }
            }
        } catch (error) {
            toaster.warning('Ошибка QR-кода');
        }
    }

    useEffect(() => {
        if (decoded) {
            checkAvailableEvents()
        }
    }, [result])
    
    return { checkAvailableEvents, availableEvents }
};

export default useAvailableEvents;
